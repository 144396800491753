import TheItemsStyleWrapper from "./TheItems.style";
import ItemImage1 from "../../../../assets/images/BBox.png";
import ItemImage3 from "../../../../assets/images/grafik_4.png";
import ItemImage2 from "../../../../assets/images/grafik_3.png";

const TheItems = () => {
  return (
    <TheItemsStyleWrapper>
      <div className="container">
        <h2>ITEMS = NFTS</h2>
        <div className="row row1">
          <div className="col-md-6">
            <img src={ItemImage1} className="itemsimage1"/>
          </div>
          <div className="col-md-6">
            <p className="TheItemsText">
              Your NFT comes with a specific number of traits, each of which is represented by an individual NFT. Furthermore, 
              the Core NFT boasts both 'Trait' and 'Item' slots. You have the option to replace trait NFTs in the Trait slots, 
              which enables you to personalize and create a unique PFP. The Item slots are used for storing investments, and 
              the NFT owner can claim rewards for holding them. You can also swap items to your preference.
            </p>
          </div>
        </div>
        <div className="row row2">
          <div className="col-md-6">
            <p className="TheItemsText">
              Investments are stored as ERC721 or ERC1155 tokens, they can be traded on any marketplace and come with their own rarity.
              <br />
              2 Trait NFTs will be burnable, to redeem 1 new trait from the next rarity tier.
            </p>
          </div>
          <div className="col-md-6">
            <img src={ItemImage2} className="itemsimage2" />
          </div>
        </div>
        <div className="row row3">
          <div className="col-md-6">
            <img src={ItemImage3} className="itemsimage3" />
          </div>
          <div className="col-md-6">
            <p className="TheItemsText">
              You will be able to claim rewards through your NFT, depending on its item slots;
You can also sell your NFT along with all of its items and traits on the open market, as well as our native marketplace.
You can also sell and buy items individually.

            </p>
          </div>
        </div>
      </div>
    </TheItemsStyleWrapper>
  );
};

export default TheItems;
