import Button from "../../../../common/button";
import AboutStyleWrapper from "./About.style";

import thumb1 from "../../../../assets/images/about/about1.png";
import thumb2 from "../../../../assets/images/about/about2.png";
import { FaDiscord } from 'react-icons/fa';

const About = () => {
  return (
    <AboutStyleWrapper>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="about_left_content">
              <h2 className="about_title">
              MetaStonez is a collection of NFTs with <br /> REAL UTILITY
              </h2>
              <p>
              MetaStonez is now an All-In-One Wealth Creation NFT project, offering unique access to innovative utilities that can help users unlock new investment opportunities. 
              Our ultimate goal is to foster a thriving community of like-minded individuals who share a passion for wealth creation and investment.  
              </p>
              <p>
              With a seamless, user-friendly experience, MetaStonez is at the forefront of the NFT movement, revolutionizing the way people create and grow wealth.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="about_right_content">
              <div className="nft_thumb">
                <img src={thumb1} alt="thumb" />
              </div> 
              <div className="nft_thumb">
                <img src={thumb2} alt="thumb" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AboutStyleWrapper>
  );
};

export default About;
