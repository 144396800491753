import aboutCardIcon1 from "../../images/icon/aboutus_card_icon1.svg"
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.svg"
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon3.svg"

 const data = {
    aboutDescription1: 'MetaStonez is now an All-In-One Wealth Creation NFT project, offering unique access to innovative utilities that can help users unlock new investment opportunities. ',
    aboutDescription2: 'Our ultimate goal is to foster a thriving community of like-minded individuals who share a passion for wealth creation and investment. With a seamless, user-friendly experience, MetaStonez is at the forefront of the NFT movement, revolutionizing the way people create and grow wealth..',
    aboutInfo: [
        {
            icon: aboutCardIcon1,
            title: "Gamming NFTs",
            text: "Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure."
        },
        {
            icon: aboutCardIcon2,
            title: "Play to Earn",
            text: 'Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original.'
        },
        {
            icon: aboutCardIcon3,
            title: "Metaverse Ready",
            text: 'Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure.'
        }
    ]
}

export default data;