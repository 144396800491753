const data = [
  {
    title: "Box A - Unmigrated",
    text: "Burn an unmigrated NFT for access to:  3 inventory slots, - Exclusive Alpha - Giveaways - Networking -Trading resources & tools - Exclusive Investment Opportunities - Voting Power: 1",
  },
 
  {
    title: "Box B - Origins",
   text: "Burn an Origins NFT for access to: 4 inventory slots, Exclusive Alpha Giveaways, Networking Trading resources & tools, Exclusive Investment Opportunities, Voting Power: 1.2 Loot Boxes: 1",
  },
  
  {
    title: "Box C - Genesis",
    text: "Burn a Genesis NFT for access to: 5 inventory slots, Exclusive Alpha, Giveaways, Networking, Trading resources & tools, Exclusive Investment Opportunities, Voting Power: 1.5, Loot Boxes: 2",
  },
  
];

export default data;
