import styled from "styled-components";

const NavWrapper = styled.nav`
  z-index: 999;
  &.blockgeni3_header {
    position: absolute;
    left: 0;
    width: 100%;
    background: #1e0f30;
    height: auto;
    transition: all 0.3s;

    &.sticky {
      position: fixed;
      top: 0;
      width: 100%;
      backdrop-filter: blur(15px);
      z-index: 998;
      margin-top: 0px;
      transition: all 0.2s;
    }
  }

  .bottom-header {    
    padding: 1rem 0;
    border-bottom: 1px solid #3e255b;
    background: #2d134c;
    position: relative;
    .container {
        display: flex;    
        align-items: center;
        .lside.col-md-9 {
          display: flex;    
          align-items: center;
          h2 {
            border-bottom: 3px solid #8645c0;
            height: 3rem;
            color: #fff;
            font-size: 2rem;
          } 
          select {
            width: 10rem;
            margin-left: 2rem;
            background: transparent;
            border: 1px solid #594571;
            border-radius: 5px;
            padding: 5px 5px;
            color: #fff;
          }
        }
        .rside.col-md-3 {
          display: flex;    
          align-items: center;
          justify-content: right;
          h2 {
            align-items: center;
            display: flex;
            p {
              margin: 0;
              align-items: center;
              display: flex;
              height: 3rem;
              color: #fff;
              font-size: 1.2rem;
              img {
                  height: 20px;
                  margin-top: -3px;
              }
            }
            p.grey {
              color: grey !important;
              font-size: 0.8rem !important;
              position: relative;
              top: 3px;
              margin-left: 6px !important;
            }
          }
        }
    }
  }

  .blockgeni3_menu_sect {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .blockgeni3_menu_left_sect {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 10%;

    .logo {
      a {
        display: inline-block;
        display: flex;
        span {
            color: #fff;
            text-decoration: none;
            padding: 0rem 0.5rem !important;
        }
      }
    }
  }

  .blockgeni3_menu_right_sect {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .blockgeni3_menu_list {
    margin-left: 3rem;
    min-width: 409px;
    width: 100%;
    ul {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;

      li {
        position: relative;
        cursor: pointer;
        padding: 10px 20px;
        height: 50px;
        &.active {
          background: #2d134d;
        }
        &:hover {
          background: #41134c;
        }

        a {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          color: #ffffff;
          span {
            position: relative;
            top: 1px;
            margin-left: 5px;
          }
        }

        &:hover {
          a {
            color: #fdfdfd;
          }
        }

        /* submenu */
        &.submenu {
          a.dd:after {
            content: "";
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #9f9f9f;
            position: relative;
            top: 50%;
            left: 10px;
          }
          .sub_menu_sect {
            background: transparent;
            border-top: 50px solid transparent;
            position: absolute;
            top: -50px;
            left: 10px;
            width: 150px;
            visibility: hidden;
            opacity: 0;
            z-index: -100;
            -webkit-transition: all 0.5s;
            transition: all 0.5s;

            .sub_menu_list {
              padding: 0px 20px 10px;
              background: #3d215c;
              -webkit-flex-wrap: wrap;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              li {
                width: 100%;
                a {
                  font-family: "Bakbak One";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 40px;
                  color: rgba(255, 255, 255, 0.8);
                  text-transform: capitalize;
                }

                &:hover {
                  a {
                    color: #00ffa3;
                  }
                }
              }
            }
          }

          &:hover {
            .sub_menu_sect {
              top: 0px;
              visibility: visible;
              opacity: 1;
              z-index: 99;
            }
          }
        }
      }
    }
  }

  .blockgeni3_menu_btns {
    display: flex;
    align-items: center;
    justify-content: right;
    align-items: center;
    button {
      color: #ffffff;
      text-transform: uppercase;
      font-family: "Bakbak One";
      font-weight: 400;
      font-size: 16px;
    }

    .menu_btn {
      display: none;
      border: none;
      background: transparent;
      cursor: pointer;
      svg {
        font-size: 40px;
      }
    }

    .join_btn {
      height: 50px;
      width: 114px;
      background-color: transparent;
    }

    .connect_btn {
      height: 50px;
      min-width: 150px;
      border: none;
      margin-left: 20px;
      background: rgba(255, 255, 255, 0.2);

      svg {
        font-size: 20px;
      }
    }
  }

  .iekbcc0.ju367va.ju367v1n{
    height: 50px;
    min-width: 150px;
    border: none;
    margin-left: 20px;
    background: rgb(150,75,193);
    background: linear-gradient(90deg, rgba(150,75,193,1) 0%, rgba(209,101,201,1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    &._12cbo8i3:hover{
      transform: scale(1) !important;
    }
    button{
      background: transparent;
      border-color: transparent;
      box-shadow: none;
    }
    .ju367v9x{
      background: transparent;
      border-color: transparent;
    }
  }

  @media (max-width: 1024px) {
    .blockgeni3_menu_list {
      margin-right: 20px;
    }
  }
  @media (max-width: 991px) {
    .blockgeni3_menu_right_sect {
      justify-content: end;
    }
    .blockgeni3_menu_btns {
      justify-content: end;
      .menu_btn {
        display: block;
      }
    }

    .blockgeni3_menu_btns {
      .join_btn {
        display: none;
      }
    }
    .blockgeni3_menu_list {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
  }

  @media (max-width: 667px) {
    .blockgeni3_menu_btns {
      .connect_btn {
        display: none;
      }

      .menu_btn {
        svg {
          font-size: 30px;
        }
      }
    }
  }

  @media (max-width: 540px) {
    .blockgeni3_menu_left_sect {
      width: 180px;
      .logo {
        img {
          width: 100px;
        }
      }
    }

    .blockgeni3_menu_right_sect {
      width: 50%;
      .blockgeni3_menu_right_sect {
        width: 50%;
      }
    }
  }
`;

export default NavWrapper;
