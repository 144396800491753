import MintOptionsStyleWrapper from "./MintOptions.style";
import MintOption1 from "../../../../assets/images/MintOption1.png";
import MintOption2 from "../../../../assets/images/MintOption2.png";
import MintOption3 from "../../../../assets/images/MintOption3.png";
import MintOption4 from "../../../../assets/images/MintOption4.png";

const MintOptions = () => {
  return (
    <MintOptionsStyleWrapper>
      <div className="container">
        <h2>MINT OPTIONS</h2>
        <div className="row row1">
          <div className="col-md-12">
            <p className="TheItemsText">
              At MetaStonez, we prioritize a seamless user experience. As such, we offer the ability to mint using a 
              diverse range of cryptocurrencies, while also providing the convenience of paying directly through a 
              fiat onramp using your credit card.
            </p>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-3">
                <img src={MintOption1} className="itemsimage2" />
              </div>
              <div className="col-md-3">
                <img src={MintOption2} className="itemsimage2" />
              </div>
              <div className="col-md-3">
                <img src={MintOption3} className="itemsimage2" />
              </div>
              <div className="col-md-3">
                <img src={MintOption4} className="itemsimage2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MintOptionsStyleWrapper>
  );
};

export default MintOptions;
