import YieldStyleWrapper from "./Yield.style";
import YieldImg1 from "../../../../assets/images/YieldImg1.png";
import YieldImg2 from "../../../../assets/images/YieldImg2.png";

const Yield = () => {
  return (
    <YieldStyleWrapper>
      <div className="container">
        <h2>YIELD FARMING PLATFORM</h2>
        <div className="row">
          <div className="col-md-6">
            <img src={YieldImg1} className="circleImage" />
          </div>
          <div className="col-md-6">
            <p className="yieldText">
              Through MetaStonez' innovative Yield Farming Platform, NFT holders will gain access to a range of established investment opportunities, 
              as well as the ability to participate in Yield Farming. By utilizing NFTs as collateral to itemize investments, we are constructing 
              the ultimate investment platform.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p className="yieldText">
              MetaStonez is more than just a label - we're always evolving to meet the needs of our community. We build for the long-term while staying 
              in line with the short-term.You can trust us to keep adapting to the ever-changing space around us, and finding new opportunities for our 
              holders.We believe in our winner-winner model and will prove so.
            </p>
          </div>
          <div className="col-md-6">
            <img src={YieldImg2} className="circleImage" />
          </div>
        </div>
      </div>
    </YieldStyleWrapper>
  );
};

export default Yield;
