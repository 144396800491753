import TheMintStyleWrapper from "./TheMint.style";
import data from "../../../../assets/data/characterSlider/dataV3";
import { Slider, SliderItem } from "../../../../common/slider/Slider";

const TheMint = () => {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    centerMode: true,
    centerPadding: "-200px",
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 667,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <TheMintStyleWrapper>
      <div className="container">
        <h2>THE MINT</h2>
        <div className="row">
          <div className="col-md-6">
            <p className="yieldText">
              All current MetaStonez NFTs will be eligible for redemption in our upcoming collection, free of charge. To facilitate this process, 
              we will be implementing a burn function for existing NFTs. Additionally, we will be introducing 5000 new NFTs into the collection, 
              available for minting at a price of 0.04 ETH."
            </p>
          </div>
          <div className="col-md-6">
            <p className="yieldText">
              The NFTs will come in 3 tiers, depending on which NFT gets burned, you will be able to mint special NFT, each with exclusive perks on their own.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Slider {...settings}>
              {data?.map((thumb, i) => (
                <SliderItem key={i}>
                  <div className="character-thumb">
                    <img src={thumb} alt="blockgeni3 nft character" />
                  </div>
                </SliderItem>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </TheMintStyleWrapper>
  );
};

export default TheMint;
