const data = [
  {
    title: "What is the MetaStonez supply ?",
    text: "In total, 3699 MetaStonez can be burned to claim a new NFT.Additionally, there will be an increase in supply of 5000.",
  },
  {
    title: "When is the mint?",
    text: "April 2023",
  },
  {
    title: "What is the mint price?",
    text: "3699 = FREE ,5000 = 0.04 ETH ($ equivalent)",
  },
  {
    title: "I have unused traits in my inventory, will there be a use for them?",
    text: "Yes, we won’t reveal, yet - but we don’t like leaving waste behind and you can be sure, that we always plan ahead.",
  },
  {
    title: "Can i burn more traits at once to get an extra rare trait?",
    text: "Yes! You can burn 2 traits of the same rarity to gain a random trait of the next rarity tier!",
  },
  
];

export default data;
