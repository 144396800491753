import checkIcon from "../../images/blog/arrow.png";
import thumb1 from "../../images/blog/roadmap-img.png";
import thumb2 from "../../images/blog/roadmap-img2.png"; 
import thumb3 from "../../images/blog/roadmap-img3.png";
import thumb4 from "../../images/blog/roadmap-img4.png";
import thumb5 from "../../images/blog/roadmap-img5.png";
const data = [
  {
    progressIcon: checkIcon,
    progress: "1",
    title: "Mint Your Core Box",
    text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced.",
    thumb: thumb1,
  },
  {
    progressIcon: checkIcon,
    progress: "2",
    title: "Mint Your Loot Box",
    text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced.",
    thumb: thumb2,
  },
  {
    progressIcon: checkIcon,
    progress: "3",
    title: "Buy / Sell / Burn Traits To Your Liking.",
    text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced.",
    thumb: thumb3,
  },
  {
    progressIcon: checkIcon,
    progress: "4",
    title: "Assemble Your PFP",
    text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced.",
    thumb: thumb4,
  },
  {
    progressIcon: checkIcon,
    progress: "5",
    title: "Invest Into Various Opportunities and Claim Your Investment NFTs",
    text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced.",
    thumb: thumb5,
  },
  {
    progressIcon: checkIcon,
    progress: "6",
    title: "Equip Your MetaStone With Items",
    text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced.",
    thumb: thumb5,
  },
  {
    progressIcon: checkIcon,
    progress: "7",
    title: "Win",
    text: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced.",
    thumb: thumb5,
  },
];

export default data;
