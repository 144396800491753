import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
} from "react-icons/fa";

import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg";

const data = [
  {
    thumb: openseaIcon,
    url: "#",
  },
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/metastonez",
  },
  {
    icon: <FaLinkedinIn />,
    url: "https://www.linkedin.com/company/metastonez/",
  },
];

export default data;
