import { FaLinkedinIn, FaTwitter, FaInstagram } from "react-icons/fa";

import avatarThumb1 from "../../images/team/Shaunt175.png";
import avatarThumb2 from "../../images/team/bo175.png";
import avatarThumb3 from "../../images/team/arsbi175.png";
import avatarThumb4 from "../../images/team/Rob175.png";
import avatarThumb5 from "../../images/team/tax175.png";
import avatarThumb6 from "../../images/team/ste175.png";
import avatarThumb7 from "../../images/team/Gary175.png";
import avatarThumb8 from "../../images/team/crew_avatar8.png";

const data = [
  {
    avatar: avatarThumb1,
    name: "Shaunt",
    designation: "Business Development / Consultant",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/shaunt%2Dpetrossian%2D0b9a7339/",
      },
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/shaunt___",
      },
    ],
  },
  {
    avatar: avatarThumb2,
    name: "BaiBorz",
    designation: "Investment Management / MediaChain Leader",
    socialLinks: [
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/Baiborz",
      },
    ],
  },
  {
    avatar: avatarThumb3,
    name: "Arsbi",
    designation: "Project + Community Management",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/arsbi-madyrsoltanov-510624259/",
      },
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/ArsbiX",
      },
    ],
  },
  {
    avatar: avatarThumb4,
    name: "Robert",
    designation: "Developer / CTO of Blockgeni3",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb5,
    name: "TehTerror",
    designation: "Tax Advisor",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/achmed-samsuradov-974284158/",
        
      },
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/Metticognito",
      },
    ],
  },
  {
    avatar: avatarThumb6,
    name: "Steven",
    designation: "Developer / CEO of Blockgeni3",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb7,
    name: "Gary",
    designation: "Blockchain Dev / Blockgeni3",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb8,
    name: "Coming soon",
    designation: "Advidor tokenomics direction",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
];

export default data;
