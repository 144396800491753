import character1 from "../../images/LootBox1.png";
import character2 from "../../images/LootBox2.png";
import character3 from "../../images/LootBox3.png";

const data = [
  character1,
  character2,
  character3,
  character1,
  character2,
  character3,
];

export default data;
