import { useModal } from "../utils/ModalContext";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import AppHeader from "../components/section/header/AppHeader/AppHeader";
import Dashboard from "../components/section/dashboard";
import CTA from "../components/section/cta/v2";
import Footer from "../components/section/footer/v3";
import MetamaskModal from "../common/modal/metamaskModal/MetamaskModal";
import WalletModal from "../common/modal/walletModal/WalletModal";

const DashboardPage = () => {
  const { walletModalvisibility, metamaskModal, isPopup, popupHandle } =
    useModal();

  return (
    <>
      <Layout>
        <GlobalStyles />
        {metamaskModal && <MetamaskModal />}
        {walletModalvisibility && <WalletModal />}
        <AppHeader />
        <Dashboard />
        <CTA />
        <Footer />
      </Layout>
    </>
  );
};

export default DashboardPage;
