import { useModal } from "../../../../utils/ModalContext";
import { useEffect, useState } from "react";
import { FaDiscord, FaWallet } from "react-icons/fa";
import { MdNotes } from "react-icons/md";
import Button from "../../../../common/button";
import NavWrapper from "./AppHeader.style";
import MobileMenu from "../mobileMenu/MobileMenu";
import AppIcon from "../../../../assets/images/whitelogo.png";
import MenuIcon0 from "../../../../assets/images/MenuIcon0.png";
import MenuIcon1 from "../../../../assets/images/MenuIcon1.png";
import MenuIcon2 from "../../../../assets/images/MenuIcon2.png";
import MenuIcon3 from "../../../../assets/images/MenuIcon3.png";
import MenuIcon4 from "../../../../assets/images/MenuIcon4.png";
import MenuIcon5 from "../../../../assets/images/MenuIcon5.png";
import BurgerIcon from "../../../../assets/images/BurgerIcon.png";

import { ConnectButton } from '@rainbow-me/rainbowkit';

const AppHeader = () => {
  const { walletModalHandle } = useModal();
  const [isMobileMenu, setMobileMenu] = useState(false);
  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };
  useEffect(() => {
    const pageLocation = window.location.pathname;
    const header = document.getElementById("navbar");
    const handleScroll = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });

    document.querySelector('[href="'+pageLocation+'"]').parentNode.classList.add("active");

    return () => {
      window.removeEventListener("sticky", handleScroll);
    };
  }, []);

  return (
    <>
      <NavWrapper className="blockgeni3_header" id="navbar">
        <div className="top-header">
          <div className="container">
            {/* Main Menu Start */}
            <div className="blockgeni3_menu_sect">
              <div className="blockgeni3_menu_left_sect">
                <div className="logo">
                  <a href="/">
                    <img src={AppIcon} alt="blockgeni3 nft logo" />
                    
                  </a>
                </div>
              </div>
              <div className="blockgeni3_menu_right_sect blockgeni3_v1_menu_right_sect">
                <div className="blockgeni3_menu_list">
                  <ul>
                    <li className="submenu">
                      <a href="#" className="dd">
                        <img src={MenuIcon0} alt="" />
                      </a>
                      <div className="sub_menu_sect">
                        <ul className="sub_menu_list">
                          <li>
                            <a href="/">Home</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a href="/dashboard">
                        <img src={MenuIcon1} alt="menu icon 1" />
                        <span>Dashboard</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={MenuIcon2} alt="menu icon 2" />
                        <span>Coming Soon</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={MenuIcon3} alt="menu icon 3" />
                        <span>Coming Soon</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={MenuIcon4} alt="menu icon 4" />
                        <span>Coming Soon</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={MenuIcon5} alt="menu icon 5" />
                        <span>Coming Soon</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="blockgeni3_menu_btns">
                  <button className="menu_btn" onClick={() => handleMobileMenu()}>
                    <MdNotes />
                  </button>
                  
                  <ConnectButton
                  label="Connect"
                  chainStatus="none"//icon,name,none
                  showBalance={false}//true,false
                  accountStatus="address"//avatar,address,
                  className="connect_btn"
                  />

                  {/* <Button
                    sm
                    variant="hovered"
                    className="connect_btn"
                    onClick={() => walletModalHandle()}
                  >
                    <FaWallet /> Connect
                  </Button> */}
                </div>
              </div>
            </div>
            {/* <!-- Main Menu END --> */}
          </div>
        </div>
        <div className="bottom-header">
          <div className="container">
            <div className="col-md-9 lside">
              <h2>8 NFTs</h2>
              <div className="filter_box_wrapper price_filter">
                <select name="PriceFilter">
                  <option defaultValue="HighestYield">Highest Yield</option>
                </select>
              </div>
            </div>
            <div className="col-md-3 rside">
              <h2>
                <p>
                  <img src={BurgerIcon} alt="blockgeni3 nft logo" />
                  33
                </p> 
                <p className="grey">
                  $STONE
                </p>
              </h2>
            </div>
          </div>
        </div>
      </NavWrapper>
      {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
    </>
  );
};

export default AppHeader;
