import FilterBox from "./filterBox/FilterBox";
import Product from "./product/Product";

import DashboardStyleWrapper from "./Dashboard.style";
import Graph from "../../../assets/images/Graph.png";
import OriginStone1 from "../../../assets/images/OriginStone1.png";
import OriginStone2 from "../../../assets/images/OriginStone2.png";
import OriginStone3 from "../../../assets/images/OriginStone3.png";
import OriginStone4 from "../../../assets/images/OriginStone4.png";
import GenesisStone1 from "../../../assets/images/GenesisStone1.png";
import GenesisStone2 from "../../../assets/images/GenesisStone2.png";
import GenesisStone3 from "../../../assets/images/GenesisStone3.png";
import GenesisStone4 from "../../../assets/images/GenesisStone4.png";
import Pin1 from "../../../assets/images/Pin1.png";
import Pin2 from "../../../assets/images/Pin2.png";

import { products, categories } from "../../../assets/data/collectionsData";

const Dashboard = () => {
  return (
    <DashboardStyleWrapper>
      <div className="container">
        <div className="row filters_row">
          <div className="col-lg-3 col-md-4">
            <div className="filter origin">
              <div class="top-section">
                <div class="pin-container orange">
                  <img src={Pin1} alt="map pin on orange background" />
                  <span>Origin</span>
                </div>
                <label className="checkbox_container">
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                  Select All
                </label>
              </div>
              <div className="bottom-section">
                <img src={OriginStone1} alt="Origin stone 1" className="active" />
                <img src={OriginStone2} alt="Origin stone 2" className="active" />
                <img src={OriginStone3} alt="Origin stone 3" className="active" />
                <img src={OriginStone4} alt="Origin stone 4" />
              </div>
            </div>
            <div className="filter genesis">
              <div class="top-section">
                <div class="pin-container red">
                  <img src={Pin2} alt="map pin on red background" />
                  <span>Genesis</span>
                </div>
                <label className="checkbox_container">
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                  Select All
                </label>
              </div>
              <div className="bottom-section">
                <img src={GenesisStone1} alt="Origin stone 1" />
                <img src={GenesisStone2} alt="Origin stone 2" />
                <img src={GenesisStone3} alt="Origin stone 3" />
                <img src={GenesisStone4} alt="Origin stone 4" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-8 center-section">
            <div className="collection_items">
              <div className="row products_row">
                <div key="tile1" className="col-lg-6 col-sm-6 col-12">
                  <div class="product">
                    <div className="product_thumb">
                      <img src={OriginStone1} alt="thumb" />
                    </div>
                    <div className="product_details">
                      <a href="#">Origin Metastonez #1</a>
                      <p>
                        <span> Time Since Last Claim <br /> 3D, 6H, 26M </span>
                      </p>
                      <p>
                        <span> $STONE Claimable <br /> 25 $STONE </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div key="tile2" className="col-lg-6 col-sm-6 col-12">
                  <div class="product">
                    <div className="product_thumb">
                      <img src={OriginStone2} alt="thumb" />
                    </div>
                    <div className="product_details">
                      <a href="#">Origin Metastonez #2</a>
                      <p>
                        <span> Time Since Last Claim <br /> 2D, 7H, 1M </span>
                      </p>
                      <p>
                        <span> $STONE Claimable <br /> 25 $STONE </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div key="tile3" className="col-lg-6 col-sm-6 col-12">
                  <div class="product">
                    <div className="product_thumb">
                      <img src={OriginStone3} alt="thumb" />
                    </div>
                    <div className="product_details">
                      <a href="#">Origin Metastonez #3</a>
                      <p>
                        <span> Time Since Last Claim <br /> 2D, 7H, 1M </span>
                      </p>
                      <p>
                        <span> $STONE Claimable <br /> 25 $STONE </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="coindata">
              <div class="stat-holder">
                <div class="col-md-4">
                  <h3>$STONE Claimed</h3>
                  <h4>250</h4>
                </div>
                <div class="col-md-4">
                  <h3>$STONE Invested</h3>
                  <h4>250</h4>
                </div>
                <div class="col-md-4">
                  <h3>$STONE Claimable</h3>
                  <h4>25</h4>
                </div>
              </div>
              <img src={Graph} alt="price graph for media coin" />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 newscol">
            <div class="news-card">
              <h3>MetaStonez NEWS</h3>
              <p>Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet</p>
            </div>
            <div class="news-card">
              <h3>MetaStonez NEWS</h3>
              <p>Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet</p>
            </div>
            <div class="news-card">
              <h3>MetaStonez NEWS</h3>
              <p>Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet</p>
            </div>
            <div class="news-card">
              <h3>MetaStonez NEWS</h3>
              <p>Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet</p>
            </div>
          </div>
        </div>
      </div>
    </DashboardStyleWrapper>
  );
};

export default Dashboard;
