import styled from "styled-components";

const DashboardStyleWrapper = styled.section`
  padding: 180px 0 140px;
  background: #2d134b;

  .coindata {
    margin-top: 3rem;
    img {
      width: 100%;
    }
    .stat-holder {
      display: flex;
      margin-bottom: 3rem;
      .col-md-4 {
        padding: 1rem !important;
        text-align: center;
        border-radius: 10px;
        width: 31%;
        color: #381a5a;
        margin: 0 0.45rem;
        background: rgb(150,75,193);
        background: linear-gradient(90deg, rgba(150,75,193,1) 0%, rgba(209,101,201,1) 100%);

        h3 {
          font-size: 18px;
        }
        h4 {
          font-size: 16px;
          margin-bottom: 0
        }
      }
    }
  }

  .products_row {
    row-gap: 40px;
    overflow: auto;
    white-space: nowrap;
    height: 440px;
  }

  .products_row::-webkit-scrollbar {
  width: 0.5em;
  }
  
  .products_row::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  .products_row::-webkit-scrollbar-thumb {
    background-color: #3e255a;
  }

  .center-section {
    padding: 0 20px 0px 0px;
  }

  .news-card {
    background: #1e0f2f5c;
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1rem;

    h3 {
      color: #fff;
      font-size: 22px;
    }

    p {
      font-size: 14px;
      margin: 0;
    }
  }

  .filter {
    width: 90%;
    .top-section {
      display: flex;
      .pin-container {
        width: 25%;
        height: 26px;
        border-radius: 5px;
        &.orange {
          background: #f79d3c;
        }
        &.red {
          background: #f90f28;
        }
        img {
          height: 16px;
          margin-left: 2px;
          margin-right: 3px;
        }
        span {
          color: #fff;
          font-size: 14px;
        }
      }
      .checkbox_container {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 12px;
        cursor: pointer;
        user-select: none;
        height: 28px;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        color: #ffffff;
        text-transform: capitalize;
        margin-left: 2rem;

        span {
          color: rgba(255, 255, 255, 0.8);
        }

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
      }
      
      .checkmark {
        position: absolute;
        top: 7px;
        left: 0;
        height: 16px;
        width: 16px;
        background: transparent;
        border-radius: 3px;
        border: 2px solid rgba(255, 255, 255, 0.12);
      }
      input:checked ~ .checkmark {
        background: #8762b1;
        border: none;
      }
      .checkmark {
        &::after {
          content: "";
          position: absolute;
          display: none;
          left: 6px;
          top: 2px;
          width: 5px;
          height: 10px;
          border: solid #111111;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
        }
      }
      input:checked ~ .checkmark {
        &::after {
          display: block;
        }
      }
    }

    &.origin {
      margin-bottom: 2rem;
      border-bottom: 2px solid #3e255a;
      padding-bottom: 1rem;
    }

    .bottom-section {
      img {
        &.active {
          border: 2px solid red;
        }
      }
    }
  }
  
  .product_thumb {
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      transition: all 0.3s;
      height: 250px;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .product_details {
    padding: 15px 24px;
    background: #120e1640;
    a {
      display: block;
      font-size: 16px;
      line-height: 1.35;
      margin-bottom: 11px;
      font-weight: 600;
      color: #ffffff;
      font-family: "Inter";
      transition: all 0.3s;
      text-align: center;

      &:hover {
        opacity: 0.7;
      }
    }

    p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 0;
      text-align: center;
      width: 100%;
      padding: 5px 0;
      background: #3a2750;
      border-radius: 10px;
      margin-bottom: 10px;

      &+p {
        background: #2c1b40;
      }

      span {
        display: flex;
        align-items: center;
        column-gap: 10px;
        text-align: center;
        margin: 0 auto;


        svg {
          cursor: pointer;
          &:hover {
            color: #f24236;
          }
        }
      }
    }
  }

  .bottom-section {
    img {
      width: 27%;
      margin-right: 1rem;
      margin-bottom: 1rem;

      &.active {

      }
    }
  }

  
  .collection_items {
    padding-top: 40px;
  }

  .price_filter {
    position: relative;

    select {
      appearance: none;
      background: transparent;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      padding: 0px 15px;
      border: 2px solid rgba(255, 255, 255, 0.12);
      height: 50px;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    option {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      padding: 0px 15px;
      background: #11181e;
    }
  }

  .filter_box_wrapper + .filter_box_wrapper {
    margin-top: 20px;
  }

  @media only screen and (max-width: 767px) {
    .collection_items {
      margin-top: 50px;
    }
  }
`;

export default DashboardStyleWrapper;
