import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";

import characterThumb from "../../../../assets/images/LootBox3.png";
import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_arrow.svg";
import mintLiveText from "../../../../assets/images/nft/mint_live_text.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";

import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { useState, useEffect } from "react";
import {
  maxSupplyCall,
  totalMintedCall,
  maxMintPerWalletCall,
  publicMintCostCall,
} from "../../../../contract/config";

const Banner = () => {
  const { mintModalHandle } = useModal();

  const [totalSupply, setTotalSupply] = useState(5000);
  const [totalMinted, setTotalMinted] = useState(5000);
  const [remainingItem, setRemainingItem] = useState(5000);
  const [maxMintPerWallet, setMaxMintPerWallet] = useState(10);
  const [publicMintCost, setPublicMintCost] = useState(0.04);

  const { address, isConnecting, isConnected, isDisconnected } = useAccount();

  const { data: maxSupplyData } = useContractRead({ ...maxSupplyCall })
  const { data: totalMintedData } = useContractRead({ ...totalMintedCall })
  const { data: maxMintPerWalletData } = useContractRead({ ...maxMintPerWalletCall })
  const { data: publicMintCostData } = useContractRead({ ...publicMintCostCall })

  useEffect(() => {
    if (isConnected) {
      if (maxSupplyData) {
        setTotalSupply(maxSupplyData.toString());
      }
      if (totalMintedData) {
        setTotalMinted(totalMintedData.toString());
      }
      if (maxSupplyData && totalMintedData) {
        setRemainingItem(totalSupply - totalMinted);
      }
      if (maxMintPerWalletData) {
        setMaxMintPerWallet(maxMintPerWalletData.toString());
      }
      if (publicMintCostData) {
        setPublicMintCost(publicMintCostData.toString() / 1000000000000000000);
      }
    }
  })

  return (
     <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="blockgeni3_v1_baner_left">
              <h2>Metastonez  NFT collections</h2>
              <h3>
                <span className="count">
                  <Counter end={totalMinted} duration={totalMinted} />
                </span>{" "}
                / {totalSupply} Minted
              </h3>
              <div className="banner_buttons">
                <Button lg variant="mint" onClick={() => mintModalHandle()}>
                  {" "}
                  Mint now
                </Button>
                <Button lg variant="outline">
                  Wishlist now
                </Button>
              </div>
              <div className="coin-info">
                <span>Max {maxMintPerWallet} NFTs per wallet . Price {publicMintCost} ETH + gas</span>
                <span>
                  MINT IS LIVE{" "}
                  <span className="highlighted">soon</span>
                </span>
                <span>Presale : 1000</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="blockgeni3_v1_baner_right">
              <div className="blockgeni3_v1_baner_right_img_sect">
                
                  
                
                <div className="blockgeni3_v1_baner_right_img_bg">
                  <img src={homeImageBG} alt="" />
                </div>
                <div className="blockgeni3_v1_baner_right_img">
                  <img src={characterThumb} alt="avater" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
