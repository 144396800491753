import checkIcon from "../../images/icon/roadmap_check.svg"
import spinnerIcon from "../../images/icon/roadmap_spiner.png"

const data = [
    {
        icon: spinnerIcon,
        title: "Stage 01 - Q1",
        subtitle: "25% Soldout",
        text: "MVP - MetaStonez Hub with Yield Farming Platform"
    }, 
    {
        icon: spinnerIcon,
        title: "Stage 02 - Q1",
        subtitle: "Mint of new collection",
        text: "Burning of New collections , Mint of new collection"
    }, 
    {
        icon: spinnerIcon,
        title: "Stage 03 - Q2",
        subtitle: "Metaverse Launch",
        text: "Yield Farming Platform - MediaChain MC Farming"
    }, 
    {
        icon: spinnerIcon,
        title: "Stage 04 - Q2",
        subtitle: "70% Soldout",
        text: "Yield farming platform - Crypto Mining"
    }, 
    {
        icon: spinnerIcon,
        title: "Stage 05 - Q3",
        subtitle: "80% Soldout", 
        text: "Whale Tank - MetaStonez Incubator Rollout"
    }, 
    {
        icon: spinnerIcon,
        title: "Stage 06 - Q4",
        subtitle: "90% Soldout", 
        text: "IRL UTILITY (Starting in Dubai)"
    }, 
]

export default data;