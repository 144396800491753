import SectionTitle from "../../../common/sectionTitle";
import data from "../../../assets/data/partnerData";
import PartnerStyledWrapper from "./Partner.style";
import collabImg1 from "../../../assets/images/COLLAB1.png"
import collabImg2 from "../../../assets/images/MediaChainWhite.png"

const Partner = () => {
  return (
    <PartnerStyledWrapper>
      <div className="our_partners_sect">
        <div className="container">
          <div className="row row1">
            <div className="col-md-6">
              <p className="partnerText">
                We are proud to have COPERTE as our digital consulting partner. Dedicated to helping businesses solve 
                their problems through an analytical lens, they offer new business models, structured project management, 
                and future-oriented IT solutions to help take MetaStonez to the top. COPERTE is taking care of procurement, 
                consulting, and maintenance of mining equipment.
              </p>
            </div>
            <div className="col-md-6">
              <img src={collabImg1} />
            </div>
          </div>
          <div className="row row2">
            <div className="col-md-6">
              <img src={collabImg2} />
            </div>
            <div className="col-md-6">
              <p className="partnerText">
                Mediachain is a huge multi-platform specially created for content creators, social media influencers and their 
                communities to monetize earnings in the crypto-virtual and real world. With over $61M in investments, and over 
                15,000 investors, MediaChain is an elite partner of ours, allowing MetaStonez holders to participate in their 
                MediaCoin Farming program.
              </p>
            </div>
          </div>
          <SectionTitle
            isCenter={true}
            subtitle="AS SEEN IN"
            title="Collaboration Partners"
            className="text-center"
          />
          <div className="our_partnar_content">
            <ul>
              {data?.map((logo, i) => (
                <li key={i}>
                  <img src={logo} alt="" />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </PartnerStyledWrapper>
  );
};

export default Partner;
