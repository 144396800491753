import characterThumb1 from "../../images/LootBox1.png";
import characterThumb2 from "../../images/LootBox2.png";
import characterThumb3 from "../../images/LootBox3.png";
import characterThumb4 from "../../images/LootBox4.png";
import characterThumb5 from "../../images/LootBox5.png";
import characterThumb6 from "../../images/LootBox6.png";

const data = [
  {
    thumb: characterThumb1,
  },
  {
    thumb: characterThumb2,
  },
  {
    thumb: characterThumb3,
  },
  {
    thumb: characterThumb4,
  },
  {
    thumb: characterThumb5,
  },
  {
    thumb: characterThumb6,
  },
  {
    thumb: characterThumb3,
  },
  {
    thumb: characterThumb1,
  },
  {
    thumb: characterThumb5,
  },
];

export default data;
